
import {Component, Vue} from 'vue-property-decorator';
import testPaperList from './list.vue';
import testPaperAdd from './add.vue';
import testPaperEdit from './edit.vue';
import userGrades from './userGrades.vue';
@Component({
  name: 'testPaper',
  components: {
    testPaperList,
    testPaperAdd,
    testPaperEdit,
    userGrades,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
