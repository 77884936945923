
import {Component, Vue} from 'vue-property-decorator';
import {questions, questionLabels} from '../../../../resources';
import {Form} from 'element-ui';
import {ObjectId} from 'bson';
import {QuestionLabel} from '@/externals/MaxCI-QuestionLabel-v1';
import {QuestionOptions} from '@/externals/MaxCI-Question-v1';
import draggable from 'vuedraggable';
import {uploadImg} from '../../../../api/publicMethod';

interface QuestionValue extends QuestionLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
@Component({
  name: 'questionEdit',
  components: {draggable},
})
export default class extends Vue {
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private submitFlag = false; //提交开关
  private activeLabel = [0];
  private dialogLabel = false; //选择标签弹窗
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private formData = {
    type: '单选' as '单选' | '多选' | '判断题',
    topic: '', //试题内容
    correct: '', //答案
    labels: [] as Array<string>,
    questions: [
      {prefix: 'A', content: '', checked: false},
      {prefix: 'B', content: '', checked: false},
      {prefix: 'C', content: '', checked: false},
      {prefix: 'D', content: '', checked: false},
    ] as Array<QuestionOptions & {checked: boolean}>,
    topicPictures: [] as Array<string>,
  };
  private rules = {
    type: [{required: true, message: '请选择', trigger: 'blur'}],
    topic: [{required: true, message: '请输入试题内容', trigger: 'blur'}],
  };
  private labelData: Array<QuestionValue> = []; //标签数据
  private action = '';
  private imgData = {};
  async created() {
    //查询标签数据
    const list = (await questionLabels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    )) as Array<QuestionValue>;
    list.forEach(item => {
      item.values = [];
      if (item.values) {
        item.spec.values.forEach(valueItem => {
          item.values.push({
            name: valueItem,
            checked: false,
          });
        });
      }
    });
    this.labelData = list;

    //查询详情
    const detailMsg =
      (
        await questions.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          }),
        )
      ).find(() => true) ?? null;
    if (detailMsg) {
      this.formData.type = detailMsg.spec.type;
      this.formData.topic = detailMsg.spec.topic;
      this.formData.topicPictures = detailMsg.spec.topicPictures ?? [];
      //this.formData.correct = detailMsg.spec.correct;
      //标签
      this.formData.labels = detailMsg.spec.labels;
      this.formData.labels.forEach(item => {
        const itemData = item.split(':');
        this.labelData.forEach(labelItem => {
          if (itemData[0] === labelItem.spec.name) {
            labelItem.values.forEach(valueItem => {
              if (itemData[1] === valueItem.name) {
                valueItem.checked = true;
              }
            });
          }
        });
      });
      this.formData.questions = detailMsg.spec.questions.map(v => {
        let checked = false;
        detailMsg.spec.correct.forEach(item => {
          if (item === v.prefix) {
            checked = true;
          }
        });
        return {
          checked: checked,
          prefix: v.prefix,
          content: v.content,
        };
      });
    }
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //删除图片
  private imgClose(index: number) {
    this.formData.topicPictures.splice(index, 1);
    this.$forceUpdate();
  }
  get images() {
    return this.formData.topicPictures.map(v => {
      return {
        uid: v,
        url: this.platformConfig.downloadUrl + v,
      };
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async getImgWH(file: any) {
    return new Promise(function (resolve) {
      var fileData = file;
      //读取图片数据
      var reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reader.onload = (e: any) => {
        if (e) {
          var data = e.target.result;
          var image = new Image();
          image.onload = () => {
            resolve(image);
          };
          image.src = data;
        }
      };
      reader.readAsDataURL(fileData);
    });
  }
  //获取图片上传地址
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async beforeAvatarUpload(file: any) {
    const image = (await this.getImgWH(file)) as {
      width: number;
      height: number;
    };
    const wImg = image.width;
    const hImg = image.height;
    let w = 0,
      h = 0,
      x = 0,
      y = 0;
    if (Number(wImg) > Number(hImg)) {
      h = Number(hImg);
      w = Math.ceil((Number(hImg) * 3) / 2);
      y = 0;
      x = (Number(wImg) - w) / 2;
    } else {
      w = Number(wImg);
      h = Math.ceil((Number(wImg) * 2) / 3);
      x = 0;
      y = (Number(hImg) - Number(wImg)) / 2;
    }
    const isJPEG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPG = isJPEG || isPNG; //限制图片格式为jpg / png
    const isLt1M = file.size / 1024 / 1024 < 1; //限制图片大小

    if (!isPG) {
      this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      throw new Error();
    } else if (!isLt1M) {
      this.$message.error('上传图片大小不能超过 1MB!');
      throw new Error();
    } else {
      if (this.platformConfig) {
        const res = await uploadImg(
          this.platformConfig.channels[0].channelId,
          this.platformConfig.applicationId,
          false,
          [
            {
              type: '裁剪',
              x: Math.round(x),
              y: Math.round(y),
              width: w,
              height: h,
            },
            {
              type: '缩放',
              mode: 'fill',
              width: 600,
              height: 400,
            },
          ],
        );
        if (!res) {
          throw new Error();
        }
        this.action = res.status?.upload?.url ?? '';
        this.imgData = res.status?.upload?.data ?? {};
      } else {
        throw new Error();
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async successImgList(value: {file: string}) {
    if (this.formData.topicPictures.length < 5) {
      this.formData.topicPictures.push(value.file);
    }
  }
  //修改
  private submitForm() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        try {
          if (this.formData.labels.length !== 1) {
            this.$message.error('请选择标签');
            return;
          }
          const correct = [] as Array<string>;
          let num = 0;
          const question = this.formData.questions.map(v => {
            if (v.checked) {
              correct.push(v.prefix);
            }
            if (v.content === '') {
              num++;
            }
            return {
              prefix: v.prefix,
              content: v.content,
            };
          }) as Array<QuestionOptions>;
          if (num > 0) {
            this.$message.error('请把选项内容填写完整');
            return;
          }
          if (correct.length === 0 && this.formData.type !== '多选') {
            this.$message.error('请选择标准答案');
            return;
          } else if (correct.length < 2 && this.formData.type === '多选') {
            this.$message.error('标准答案数量必须大于1个');
            return;
          }
          this.submitFlag = true;
          questions
            .update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              update =>
                update.$set(s => {
                  s(f => f('spec')('type'), this.formData.type)(
                    f => f('spec')('topicPictures'),
                    this.formData.topicPictures,
                  )(f => f('spec')('topic'), this.formData.topic)(
                    f => f('spec')('correct'),
                    correct,
                  )(f => f('spec')('questions'), question)(
                    f => f('spec')('labels'),
                    this.formData.labels,
                  );
                  return s;
                }),
            )
            .then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
                this.bactBtn();
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //选择答案
  private changeCheck(index: number, checked: boolean) {
    if (this.formData.type !== '多选') {
      if (checked) {
        this.formData.questions.forEach(item => {
          item.checked = false;
        });
        this.formData.questions[index].checked = true;
      }
    }
  }
  //添加答案
  private addAnswer() {
    if (this.formData.type !== '判断题') {
      this.formData.questions.push({
        prefix: String.fromCharCode(this.formData.questions.length + 65),
        content: '',
        checked: false,
      });
    }
  }
  //删除答案
  private removeAnswer(index: number) {
    this.formData.questions.splice(index, 1);
    this.formData.questions = this.formData.questions.map((v, i) => {
      return {
        checked: v.checked,
        content: v.content,
        prefix: String.fromCharCode(i + 65),
      };
    });
  }
  //切换类型
  private changeType() {
    if (this.formData.type === '判断题') {
      this.formData.questions = [
        {prefix: 'A', content: '', checked: false},
        {prefix: 'B', content: '', checked: false},
      ];
    } else {
      this.formData.questions = [
        {prefix: 'A', content: '', checked: false},
        {prefix: 'B', content: '', checked: false},
        {prefix: 'C', content: '', checked: false},
        {prefix: 'D', content: '', checked: false},
      ];
    }
  }
  //处理标签数据
  private getLabel(label: string, type: string) {
    let value = '';
    if (type === 'name') {
      value = label.split(':')[0];
    } else {
      value = label.split(':')[1];
    }
    return value;
  }
  //选择标签
  private changeLabel(index: number, indexValue: number) {
    this.labelData.forEach(v => {
      v.values.forEach(item => {
        item.checked = false;
      });
    });
    this.labelData[index].values[indexValue].checked =
      !this.labelData[index].values[indexValue].checked;
  }
  //选择标签保存
  private labelSumbit() {
    const labels = [] as Array<string>;
    this.labelData.forEach(item => {
      item.values.forEach(valueItem => {
        if (valueItem.checked) {
          labels.push(item.spec.name + ':' + valueItem.name);
        }
      });
    });
    this.formData.labels = labels;
    this.dialogLabel = false;
  }
  //返回
  private bactBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
