
import {Component, Vue, Watch} from 'vue-property-decorator';
import {testPapes, questions} from '../../../../resources';
import {Form} from 'element-ui';
import {ObjectId} from 'bson';
import moment from 'moment';
import selectQuestion from '../selectQuestion/index.vue';
import {Question} from '@/externals/MaxCI-Question-v1';
import {TakeTopic} from '@/externals/MaxCI-TestPaper-v1';
import uploadImage from '@/components/uploadImage/index.vue';

@Component({
  name: 'testPaperAdd',
  components: {
    selectQuestion,
    uploadImage,
  },
})
export default class extends Vue {
  private validScore = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('分数格式错误'));
    } else {
      callback();
    }
  };
  private validNum = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*$)/.test(value)) {
      callback(new Error('请输入正整数'));
    } else {
      callback();
    }
  };
  private submitFlag = false; //提交开关
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private formData = {
    type: '考试总时长',
    name: '', //标题
    testTime: [] as Array<Date>, //考试时间
    totalScore: null as null | number, //总分
    qualifyScore: null as null | number, //及格分
    questionNumber: null as null | number, //题目数量
    totalTime: null as null | number, //考试总时长
    questionTime: null as null | number, //每题限时
    background: '', //背景图
    questions: [] as Array<{
      label: string;
      score?: number;
      totalTime?: number;
      questionId: ObjectId;
    }>, //题目列表
    upset: false, //打乱题目
    questionNumberType: '0',
    takeTopic: [] as Array<{
      label: string;
      number: number;
      total: number;
    }>,
  };
  private rules = {
    type: [{required: true, message: '请选择', trigger: 'blur'}],
    name: [{required: true, message: '请输入标题', trigger: 'blur'}],
    testTime: [{required: true, message: '请选择考试时间', trigger: 'blur'}],
    totalScore: [
      {required: true, message: '请输入试卷总分', trigger: 'blur'},
      {validator: this.validScore, trigger: 'blur'},
    ],
    qualifyScore: [
      {required: true, message: '请输入及格分', trigger: 'blur'},
      {validator: this.validScore, trigger: 'blur'},
    ],
    questionNumber: [
      {required: true, message: '请输入题目数量', trigger: 'blur'},
      {validator: this.validNum, trigger: 'blur'},
    ],
    background: [{required: true, message: '请选择', trigger: 'blur'}],
  };
  private currentQuestions = [] as Array<Question>; //当前页问题
  private listParams = {
    page: 1,
    limit: 10,
  };

  @Watch('formData.questions', {deep: true})
  private async changeTakeTopic() {
    let labels = [] as Array<string>;
    let labelsSet = [] as Array<{
      label: string;
      number: number;
      total: number;
    }>;
    this.formData.questions.forEach(item => {
      labels.push(item.label);
    });
    labels = Array.from(new Set(labels));
    labelsSet = labels.map(v => {
      return {label: v, number: 0, total: 0};
    });
    this.formData.questions.forEach(item => {
      labelsSet.forEach(v => {
        if (item.label === v.label) {
          v.number++;
          v.total++;
        }
      });
    });
    this.formData.takeTopic = labelsSet;
  }
  //选择题目总数
  get total() {
    return this.formData.questions.length;
  }
  //查询问题详情
  private async updateQuestion() {
    const questionIds = this.formData.questions
      .slice(
        (this.listParams.page - 1) * this.listParams.limit,
        this.listParams.page * this.listParams.limit,
      )
      .map(v => v.questionId);
    const list = await questions.find(stage =>
      stage.$match(match => {
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        )(
          f => f('_id'),
          e => e.$in(questionIds),
        );
        return match;
      }),
    );
    list.sort((a, b) => {
      const sortQuestionId = questionIds.map(v => v.toHexString());
      return (
        sortQuestionId.indexOf(a._id.toHexString()) -
        sortQuestionId.indexOf(b._id.toHexString())
      );
    });
    this.currentQuestions = list;
  }
  //查询问题详情 选择页码
  private handleSizeChange(value: number) {
    this.listParams.limit = value;
    this.listParams.page = 1;
    this.updateQuestion();
  }
  //查询问题详情 切换当前页
  private handleCurrentChange(value: number) {
    this.listParams.page = value;
    this.updateQuestion();
  }
  //切换选题数量
  private changeQuestionNumber() {
    this.formData.questionNumber = null;
    (this.$refs.formData as Form).clearValidate();
  }
  //选择问题
  private changeQuestions(questions: Array<Question>) {
    const oldQuestions = questions.map(v => {
      return {
        label: v.spec.labels[0],
        questionId: v._id,
      };
    });
    let json = this.formData.questions.concat(oldQuestions);
    let newJson = [];
    for (let i = 0; i < json.length; i++) {
      let flag = true;
      for (let j = 0; j < newJson.length; j++) {
        if (newJson[j].questionId.equals(json[i].questionId)) {
          flag = false;
        }
      }
      if (flag) {
        //判断是否重复
        newJson.push(json[i]);
      }
    }
    this.formData.questions = newJson;
    this.listParams.page = 1;
    this.updateQuestion();
  }
  //处理标签数据
  private getLabel(label: string, type: string) {
    let value = '';
    if (type === 'name') {
      value = label.split(':')[0];
    } else {
      value = label.split(':')[1];
    }
    return value;
  }
  //删除题目
  private delSumbit(id: ObjectId) {
    for (let i = 0; i < this.formData.questions.length; i++) {
      if (this.formData.questions[i].questionId.equals(id)) {
        this.formData.questions.splice(i, 1);
        break;
      }
    }
    if (this.currentQuestions.length === 1) {
      this.listParams.page = 1;
    }
    this.updateQuestion();
  }
  //切换类型
  private changeType() {
    this.formData.totalTime = null;
    this.formData.questionTime = null;
  }
  //新增
  private submitForm() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        try {
          if (this.formData.type === '考试总时长') {
            if (
              Number(this.formData.totalTime) === 0 ||
              !this.formData.totalTime ||
              !/^[1-9]\d*$/.test(this.formData.totalTime.toString())
            ) {
              this.$message.error('考试总时长必须为正整数');
              return;
            }
          } else {
            if (
              Number(this.formData.questionTime) === 0 ||
              !this.formData.questionTime ||
              !/^[1-9]\d*$/.test(this.formData.questionTime.toString())
            ) {
              this.$message.error('每题限时必须为正整数');
              return;
            }
          }

          //题目数量验证
          let questionNumber = 0 as number | Array<TakeTopic>;
          let takeNum = 0,
            topicNum = 0,
            totalNum = 0;

          if (this.formData.questionNumberType === '0') {
            if (
              this.formData.questions.length <
              Number(this.formData.questionNumber)
            ) {
              this.$message.error('选择题目数量小于题目数量');
              return;
            }
            questionNumber = Number(this.formData.questionNumber);
          } else {
            this.formData.takeTopic.forEach(item => {
              totalNum = totalNum + Number(item.number);
              if (item.number > item.total) {
                takeNum++;
              }
              if (!/^[1-9]\d*$/.test(item.number.toString())) {
                topicNum++;
              }
            });
            if (this.formData.questions.length < Number(totalNum)) {
              this.$message.error('选择题目数量小于题目数量');
              return;
            }
            if (topicNum > 0) {
              this.$message.error('选题数量必须为正整数');
              return;
            }
            if (takeNum > 0) {
              this.$message.error('选题数量不能大于该分类题目总数量');
              return;
            }
            questionNumber = this.formData.takeTopic.map(v => {
              return {
                label: v.label,
                number: Number(v.number),
              };
            });
          }
          const question = this.formData.questions.map(v => {
            let totalTime = 0;
            if (this.formData.type === '每题限时') {
              totalTime = Number(this.formData.questionTime);
            }
            return {
              score:
                this.formData.questionNumberType === '0'
                  ? Number(this.formData.totalScore) /
                    Number(this.formData.questionNumber)
                  : Number(this.formData.totalScore) / Number(totalNum),
              totalTime: totalTime,
              label: v.label,
              questionId: v.questionId,
            };
          });
          this.submitFlag = true;
          testPapes
            .create([
              {
                spec: {
                  name: this.formData.name,
                  ...(this.formData.type === '考试总时长'
                    ? {
                        totalTime: Math.round(
                          Number(this.formData.totalTime) * 60,
                        ),
                      }
                    : {}),
                  testTime: {
                    begin: moment(this.formData.testTime[0])
                      .startOf('day')
                      .toDate(),
                    end: moment(this.formData.testTime[1])
                      .endOf('day')
                      .toDate(),
                  },
                  qualifyScore: Number(this.formData.qualifyScore),
                  totalScore: Number(this.formData.totalScore),
                  questionNumber: questionNumber,
                  questions: question,
                  background: this.formData.background,
                  applicationId: this.applicationId,
                  upset: this.formData.upset,
                },
              },
            ])
            .then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
                this.bactBtn();
              } else {
                this.$message.error('保存失败');
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //选择图片
  private changeUploadImage(id: string) {
    this.formData.background = id;
  }
  //返回
  private bactBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
