
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {questions, questionLabels} from '../../../../resources';
import {ObjectId} from 'bson';
import {Question} from '@/externals/MaxCI-Question-v1';
import moment from 'moment';
import {QuestionLabel} from '@/externals/MaxCI-QuestionLabel-v1';

interface QuestionValue extends QuestionLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
@Component({
  name: 'questionList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private dialogSearchLabel = false;
  private labelData: Array<QuestionValue> = []; //设置标签数据
  private searchLabelData: Array<QuestionValue> = []; //搜索标签数据
  private dialogDel = false; //驳回弹窗
  private operateId: null | ObjectId = null;
  private total = 0;
  private list: Array<Question> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private searchData = {
    type: '',
    topic: '',
    label: [] as Array<string>,
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }
    this.updateList();
    //查询标签数据
    const list = (await questionLabels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    )) as Array<QuestionValue>;
    list.forEach(item => {
      item.values = [];
      if (item.values) {
        item.spec.values.forEach(valueItem => {
          item.values.push({
            name: valueItem,
            checked: false,
          });
        });
      }
    });
    this.labelData = list;
    this.searchLabelData = list;
  }
  //重置搜索标签
  private resetLabel() {
    this.searchLabelData.forEach(item => {
      item.values.forEach(valItem => {
        valItem.checked = false;
      });
    });
    this.searchData.label = [];
    this.checkList();
  }
  //搜索选中标签
  private searchSelectLabel() {
    const labels = [] as Array<string>;
    this.searchLabelData.forEach(item => {
      item.values.forEach(valItem => {
        if (valItem.checked) {
          labels.push(item.spec.name + ':' + valItem.name);
        }
      });
    });
    this.searchData.label = labels;
    this.checkList();
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //处理标签数据
  private getLabel(label: string, type: string) {
    let value = '';
    if (type === 'name') {
      value = label.split(':')[0];
    } else {
      value = label.split(':')[1];
    }
    return value;
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //修改
  private toEdit(id: ObjectId) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'edit',
        id: id.toHexString(),
      },
    });
  }
  //删除提示
  private delPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogDel = true;
  }
  //删除
  private async delSumbit() {
    try {
      if (this.operateId) {
        const question = await questions.delete(filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.operateId as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        );
        if (question) {
          this.dialogDel = false;
          this.operateId = null;
          this.$message.success('已删除');
          this.updateList();
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await questions.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            if (this.searchData.type) {
              match(
                f => f('spec')('type'),
                e => e.$eq(this.searchData.type as '单选' | '多选' | '判断题'),
              );
            }
            if (this.searchData.label.length > 0) {
              match(
                f => f('spec')('labels'),
                e => e.$in(this.searchData.label),
              );
            }
            if (this.searchData.topic) {
              match(
                f => f('spec')('topic'),
                e => e.$regex(new RegExp(this.searchData.topic)),
              );
            }
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //新增
  private addBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'add'},
    });
  }
}
