
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {answerRecords, users, testPapes, students} from '../../../../resources';
import {ObjectId} from 'bson';
import {AnswerRecordSpec} from '@/externals/MaxCI-AnswerRecord-v1';
import lodash from 'lodash';
import xlsx from 'xlsx';
import moment from 'moment';

interface ReportContent {
  name: string;
  rate: number;
  total: number;
  actual: number;
  unfinished: number;
  joinCount: number;
}
interface ListSpec {
  _id: ObjectId;
  spec: AnswerRecordSpec;
}
@Component({
  name: 'userGrades',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private loading = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private testPaperInfoData = {} as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private userInfoData = {} as any;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private total = 0;
  private list: Array<ListSpec> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private searchData = {
    studentId: '',
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private testPaperQualifyScore = 0; //及格分
  private testPaperName = '';
  async created() {
    const testPaperData = await testPapes.find(stage =>
      stage.$match(match =>
        match(
          f => f('_id'),
          e => e.$eq(this.detailId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    );
    this.testPaperName = testPaperData[0].spec.name;
    this.testPaperQualifyScore = testPaperData[0].spec.qualifyScore;
    this.updateList();
  }
  private getUserInfo(id: ObjectId) {
    let userName = '--';
    if (id && this.userInfoData[id.toHexString()]) {
      userName = this.userInfoData[id.toHexString()].spec.phone;
    }
    return userName;
  }
  //秒转换成是时分秒
  private formatSeconds(value: string) {
    let result = parseInt(value);
    let h =
      Math.floor(result / 3600) < 10
        ? '0' + Math.floor(result / 3600)
        : Math.floor(result / 3600);
    let m =
      Math.floor((result / 60) % 60) < 10
        ? '0' + Math.floor((result / 60) % 60)
        : Math.floor((result / 60) % 60);
    let s =
      Math.floor(result % 60) < 10
        ? Math.floor(result % 60)
        : Math.floor(result % 60);

    let res = '';
    if (h !== '00') res += `${h}h`;
    if (m !== '00') res += `${m}′`;
    res += `${s}″`;
    return res;
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await answerRecords.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('spec')('testPaperId'),
              e => e.$eq(this.detailId),
            );
            if (this.searchData.studentId) {
              match(
                f => f('spec')('studentInfo')('studentId'),
                e => e.$eq(this.searchData.studentId),
              );
            }
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('spec')('score'), '降序')(
                    f => f('spec')('timeConsum'),
                    '升序',
                  ),
                )
                .$group(
                  e => e.$fieldPath(f => f('spec')('userId')),
                  group =>
                    group('spec', e =>
                      e.$first(first => first.$fieldPath(f => f('spec'))),
                    ),
                )
                .$sort(sort =>
                  sort(f => f('spec')('score'), '降序')(
                    f => f('spec')('timeConsum'),
                    '升序',
                  )(f => f('spec')('studentInfo')('studentId'), '升序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage =>
              countStage
                .$group(
                  e => e.$fieldPath(f => f('spec')('userId')),
                  group =>
                    group('spec', e =>
                      e.$first(first => first.$fieldPath(f => f('spec'))),
                    ),
                )
                .$count('count'),
            ),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      let userIdData: Array<ObjectId> = [];
      list[0].table.forEach(item => {
        userIdData.push(item.spec.userId);
      });
      //用户信息
      userIdData = Array.from(new Set(userIdData));
      const userData = await users.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$in(userIdData),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        ),
      );
      this.userInfoData = lodash.zipObject(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        userData.map(v => v._id.toHexString()) as any,
        userData,
      );
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //导出未参与
  private async exportNotInvolved() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      reportList.push(['学院', '姓名', '学号', '专业', '年级', '班级']);
      //查询有多少学院
      const collegeData = (await students.find(stage =>
        stage
          .$match(match =>
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          )
          .$group(e => e.$fieldPath(f => f('spec')('college'))),
      )) as Array<{_id: string}>;
      for (let i = 0; i < collegeData.length; i++) {
        //查询每个学院学生总数
        const count = (
          await students.find(stage =>
            stage
              .$match(match =>
                match(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('college'),
                  e => e.$eq(collegeData[i]._id),
                ),
              )
              .$count('count'),
          )
        )[0].count;
        //每100个查询一次
        let j = 0;
        while (j < Math.ceil(count / 100)) {
          j++;
          const studentData = await students.find(stage =>
            stage
              .$match(match =>
                match(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('college'),
                  e => e.$eq(collegeData[i]._id),
                ),
              )
              .$sort(sort => sort(f => f('spec')('studentId'), '降序'))
              .$skip((j - 1) * 100)
              .$limit(100),
          );
          const studentIds = studentData.map(v => v.spec.studentId);
          const list = (await answerRecords.find(stage =>
            stage
              .$match(match =>
                match(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('testPaperId'),
                  e => e.$eq(this.detailId),
                )(
                  f => f('spec')('studentInfo')('studentId'),
                  e => e.$in(studentIds),
                ),
              )
              .$group(e =>
                e.$fieldPath(f => f('spec')('studentInfo')('studentId')),
              ),
          )) as Array<{_id: string}>;
          if (studentData.length > list.length) {
            for (let x = 0; x < studentData.length; x++) {
              const student = studentData[x];
              let isExist = false;
              for (let y = 0; y < list.length; y++) {
                if (student.spec.studentId === list[y]._id) {
                  isExist = true;
                  break;
                }
              }
              if (!isExist) {
                reportList.push([
                  student.spec.college,
                  student.spec.name,
                  student.spec.studentId,
                  student.spec.major,
                  student.spec.grade,
                  student.spec.class,
                ]);
              }
            }
          }
        }
      }
      const filename = moment().format('YYYY-MM-DD') + '未参与竞赛名单.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //导出汇总
  private async exportSummary() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      const count = (
        await answerRecords.find(stage =>
          stage
            .$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('spec')('testPaperId'),
                e => e.$eq(this.detailId),
              );
              return match;
            })
            .$sort(sort => sort(f => f('spec')('score'), '降序'))
            .$group(
              e => e.$fieldPath(f => f('spec')('studentInfo')('studentId')),
              group =>
                group('score', e =>
                  e.$first(first => first.$fieldPath(f => f('spec')('score'))),
                )('college', e =>
                  e.$first(first =>
                    first.$fieldPath(f => f('spec')('studentInfo')('college')),
                  ),
                ),
            )
            .$group(
              e => e.$fieldPath(f => f('college')),
              group =>
                group('count', e =>
                  e.$sum(sum =>
                    sum.$cond(
                      _if =>
                        _if.$gte(
                          e => e.$fieldPath(f => f('score')),
                          e => e.$literal(this.testPaperQualifyScore),
                        ),
                      _then => _then.$literal(1),
                      _else => _else.$literal(0),
                    ),
                  ),
                ),
            )
            .$count('count'),
        )
      )[0].count;
      reportList.push([
        '完成率排名',
        '学院名称',
        '完成率',
        '应完成人数',
        '通过人数',
        '未通过人数',
        '参与人数',
        '未参与人数',
      ]);
      const reportContent = [] as Array<ReportContent>;
      let i = 0;
      while (i < Math.ceil(count / 100)) {
        i++;
        const list = await answerRecords.find(stage =>
          stage
            .$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('spec')('testPaperId'),
                e => e.$eq(this.detailId),
              );
              return match;
            })
            .$sort(sort => sort(f => f('spec')('score'), '降序'))
            .$group(
              e => e.$fieldPath(f => f('spec')('studentInfo')('studentId')),
              group =>
                group('score', e =>
                  e.$first(first => first.$fieldPath(f => f('spec')('score'))),
                )('college', e =>
                  e.$first(first =>
                    first.$fieldPath(f => f('spec')('studentInfo')('college')),
                  ),
                ),
            )
            .$group(
              e => e.$fieldPath(f => f('college')),
              group =>
                group('count', e =>
                  e.$sum(sum =>
                    sum.$cond(
                      _if =>
                        _if.$gte(
                          e => e.$fieldPath(f => f('score')),
                          e => e.$literal(this.testPaperQualifyScore),
                        ),
                      _then => _then.$literal(1),
                      _else => _else.$literal(0),
                    ),
                  ),
                )('joinCount', e => e.$sum(sum => sum.$literal(1))),
            )
            .$skip((i - 1) * 100)
            .$limit(100),
        );
        let collegeNameData: Array<string> = [];
        list.forEach(item => {
          if (item._id) {
            collegeNameData.push(item._id);
          }
        });
        //学院信息
        collegeNameData = Array.from(new Set(collegeNameData));
        const studentsData = await students.find(stage =>
          stage
            .$match(match =>
              match(
                f => f('spec')('college'),
                e => e.$in(collegeNameData),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            )
            .$facet(facet =>
              facet('table', tableStage =>
                tableStage.$group(
                  e => e.$fieldPath(f => f('spec')('college')),
                  group => group('count', e => e.$sum(sum => sum.$literal(1))),
                ),
              ),
            ),
        );
        const studentInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          studentsData[0].table.map(v => v._id) as any,
          studentsData[0].table,
        );
        list.forEach(item => {
          if (item._id) {
            reportContent.push({
              name: item._id,
              rate:
                Number(item.count) / Number(studentInfoData[item._id].count),
              total: Number(studentInfoData[item._id].count),
              actual: item.count,
              unfinished: Number(item.joinCount) - Number(item.count),
              joinCount: item.joinCount,
            });
          }
        });
      }
      reportContent.sort((a: ReportContent, b: ReportContent) => {
        return b.rate - a.rate;
      });
      reportContent.forEach((item, index) => {
        reportList.push([
          index + 1,
          item.name,
          (item.rate * 100).toFixed(2) + '%',
          item.total,
          item.actual,
          item.unfinished,
          item.joinCount,
          Number(item.total) - Number(item.joinCount),
        ]);
      });
      const filename = moment().format('YYYY-MM-DD') + '成绩汇总.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  // 导出报表
  private async exportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      const count = (
        await answerRecords.find(stage =>
          stage
            .$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('spec')('testPaperId'),
                e => e.$eq(this.detailId),
              );
              if (this.searchData.studentId) {
                match(
                  f => f('spec')('studentInfo')('studentId'),
                  e => e.$eq(this.searchData.studentId),
                );
              }
              return match;
            })
            .$group(
              e => e.$fieldPath(f => f('spec')('userId')),
              group =>
                group('spec', e =>
                  e.$first(first => first.$fieldPath(f => f('spec'))),
                ),
            )
            .$count('count'),
        )
      )[0].count;
      reportList.push([
        '用户账号',
        '姓名',
        '学号',
        '竞赛标题',
        '考试得分',
        '答题耗时',
        '学院',
        '专业',
        '年级',
        '班级',
      ]);
      let i = 0;
      while (i < Math.ceil(count / 100)) {
        i++;
        const list = await answerRecords.find(stage =>
          stage
            .$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('spec')('testPaperId'),
                e => e.$eq(this.detailId),
              );
              if (this.searchData.studentId) {
                match(
                  f => f('spec')('studentInfo')('studentId'),
                  e => e.$eq(this.searchData.studentId),
                );
              }
              return match;
            })
            .$sort(sort =>
              sort(f => f('spec')('score'), '降序')(
                f => f('spec')('timeConsum'),
                '升序',
              ),
            )
            .$group(
              e => e.$fieldPath(f => f('spec')('userId')),
              group =>
                group('spec', e =>
                  e.$first(first => first.$fieldPath(f => f('spec'))),
                ),
            )
            .$sort(sort =>
              sort(f => f('spec')('score'), '降序')(
                f => f('spec')('timeConsum'),
                '升序',
              )(f => f('spec')('studentInfo')('studentId'), '升序'),
            )
            .$skip((i - 1) * 100)
            .$limit(100),
        );
        let userIdData: Array<ObjectId> = [];
        list.forEach(item => {
          userIdData.push(item.spec.userId);
        });
        //用户信息
        userIdData = Array.from(new Set(userIdData));
        const userData = await users.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$in(userIdData),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        );
        const userInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          userData.map(v => v._id.toHexString()) as any,
          userData,
        );
        list.forEach(item => {
          reportList.push([
            userInfoData[item.spec.userId.toHexString()].spec.phone,
            item.spec.studentInfo?.name,
            item.spec.studentInfo?.studentId,
            this.testPaperName,
            item.spec.score,
            this.formatSeconds(item.spec.timeConsum.toString()),
            item.spec.studentInfo?.college,
            item.spec.studentInfo?.major,
            item.spec.studentInfo?.grade,
            item.spec.studentInfo?.class ? item.spec.studentInfo?.class : '--',
          ]);
        });
      }
      const filename = moment().format('YYYY-MM-DD') + '成绩明细.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //返回
  private bactBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
