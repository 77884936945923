
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {testPapes} from '../../../../resources';
import {ObjectId} from 'bson';
import {TestPaper, DateRange, TakeTopic} from '@/externals/MaxCI-TestPaper-v1';
import moment from 'moment';

@Component({
  name: 'testPaperList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private operateId: null | ObjectId = null;
  private total = 0;
  private list: Array<TestPaper> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private searchData = {
    name: '',
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    this.updateList();
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //获取题目数量
  private getQuestionNumber(questionNumber: number | Array<TakeTopic>) {
    let number = 0;
    if (typeof questionNumber === 'number') {
      number = questionNumber;
    } else {
      (questionNumber as Array<TakeTopic>).forEach(item => {
        number = item.number + number;
      });
    }
    return number;
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //修改
  private toEdit(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'edit',
        id: id.toHexString(),
      },
    });
  }
  //成绩
  private toGrade(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'userGrades',
        id: id.toHexString(),
      },
    });
  }
  //获取状态
  private getTestStatus(time: DateRange) {
    let status = '--';
    if (moment().isBefore(moment(time.end))) {
      if (moment().isBefore(moment(time.begin))) {
        status = '未开始';
      } else {
        status = '进行中';
      }
    } else {
      status = '已结束';
    }
    return status;
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await testPapes.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //新增
  private addBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'add'},
    });
  }
}
