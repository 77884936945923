
import {Component, Vue, Prop} from 'vue-property-decorator';
import {questionLabels, questions} from '../../../../resources';
import {ObjectId} from 'bson';
import {QuestionLabel} from '@/externals/MaxCI-QuestionLabel-v1';
import {Question} from '@/externals/MaxCI-Question-v1';
import Pagination from '@/components/Pagination/index.vue';

@Component({
  name: 'selectQuestion',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop()
  private questions: Array<{
    label: string;
    score?: number;
    totalTime?: number;
    questionId: ObjectId;
  }>;
  private multipleSelection: Array<Question> = [];
  private total = 0;
  private list: Array<Question> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 50,
  };
  private dialogQuestion = false;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private labelsData: Array<QuestionLabel & {checked: Array<string>}> = []; //可选择的标签数据
  async created() {
    //查询标签
    this.labelsData = (
      await questionLabels.find(stage =>
        stage.$match(match =>
          match.$and(and => {
            and(query =>
              query(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            );
            return and;
          }),
        ),
      )
    ).map(v => {
      return {
        ...v,
        checked: [],
      };
    });
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const labels = [] as Array<string>;
      this.labelsData.forEach(item => {
        item.checked.forEach(val => {
          labels.push(`${item.spec.name}:${val}`);
        });
      });
      const list = await questions.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            if (labels.length > 0) {
              match(
                f => f('spec')('labels'),
                e => e.$in(labels),
              );
            }
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      const mergeList = [] as Array<Question>;
      this.list.forEach(item => {
        this.questions.forEach(q => {
          if (item._id.equals(q.questionId)) {
            mergeList.push(item);
          }
        });
      });
      setTimeout(() => {
        mergeList.forEach(item => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this as any).$refs.multipleTable.toggleRowSelection(item, true);
        });
      }, 200);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //处理标签数据
  private getLabel(label: string, type: string) {
    let value = '';
    if (type === 'name') {
      value = label.split(':')[0];
    } else {
      value = label.split(':')[1];
    }
    return value;
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //显示弹窗
  private selectPopUps() {
    this.checkList();
    this.dialogQuestion = true;
  }
  //选中
  handleSelectionChange(arr: Array<Question>) {
    this.multipleSelection = arr;
  }
  //保存标签
  private selectSumbit() {
    this.dialogQuestion = false;
    this.$emit('changeQuestions', this.multipleSelection);
  }
}
