
import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import question from './components/question/index.vue';
import questionLabel from './components/questionLabel/index.vue';
import testPaper from './components/testPaper/index.vue';
@Component({
  name: 'order',
  components: {
    Submenu,
    question,
    questionLabel,
    testPaper,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu: Array<{
    top: string;
    list: Array<{name: string; active: boolean}>;
  }> = [
    {
      top: '题库管理',
      list: [
        {name: '题目列表', active: true},
        {name: '题目标签', active: false},
      ],
    },
    {
      top: '考试管理',
      list: [{name: '知识竞赛', active: false}],
    },
  ];
  async created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.menu = this.$route.query.menu
      ? (this.$route.query.menu as string)
      : '题目列表';
  }
}
